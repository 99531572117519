export default {
  title: "行业洞察",
  englishTitle: "Industry Insight",
  content:
    "2017年，红布林诞生！一路走来，我们与许多热爱美、积极生活的用户共创了循环时尚这种新的生活方式。我们都相信，好品牌、好品质、好设计的单品值得被循环起来，并希望将这种价值观传递给更多人。\n成立至今，很骄傲地说，我们做到了让全品类、2000+时尚品牌都可以轻松循环，并为每一个品类打造了可靠的鉴定、评级和定价服务。我们也见证了越来越多的用户，更勇敢地尝试多元的品牌和设计，更轻松地处理自己的闲置。\n这是一个新世界，也是一种新生活，让每一件商品都变得物尽其用，每一种审美价值都被鼓励，地球环境也因此变得更可持续发展。故事还在继续，期待你的加入～",
  items: [
    {
      image: "https://imgcdn.aplum.com/o_1gufj3sfh1illroms7g9mk1kt0.png",
      url: "https://agreement.cdn.aplum.com/%E7%BA%A2%E5%B8%83%E6%9E%972023%E5%BE%AA%E7%8E%AF%E6%97%B6%E5%B0%9A%E7%A2%B3%E5%87%8F%E6%8E%92%E5%AE%9E%E8%B7%B5%E6%8A%A5%E5%91%8A.pdf",
    },
    {
      image: "https://imgcdn.aplum.com/o_1gmaaonbs6c31s2i1e0oar6kaif.png",
      url: "https://agreement.cdn.aplum.com/2023%E5%BE%AA%E7%8E%AF%E6%97%B6%E5%B0%9A%E8%A1%8C%E4%B8%9A%E8%B6%8B%E5%8A%BF%E6%8A%A5%E5%91%8A.pdf",
    },
    {
      image: "https://imgcdn.aplum.com/o_1gfleg8jk1sqin0d1o4k66r17kak.png",
      url: "https://agreement.cdn.aplum.com/2022%E4%B8%AD%E5%9B%BD%E5%BE%AA%E7%8E%AF%E6%97%B6%E5%B0%9A%E4%BA%A7%E4%B8%9A%E5%88%9B%E6%96%B0%E7%A0%94%E7%A9%B6%E6%8A%A5%E5%91%8A.pdf",
    },
    {
      image: "https://imgcdn.aplum.com/o_1gfleh87s9pk1bnv7r17571an0p.png",
      url: "/imageView/2021RH2",
    },
    // {
    //   image: "https://imgcdn.aplum.com/o_1gflehmr885ocl8p864q11tku.png",
    //   url: "/imageView/2021RH1",
    // },
    {
      image: "https://imgcdn.aplum.com/o_1gflf1qgh19bgp4r1n5n18tir3613.png",
      url: "/imageView/2021E",
    },
    {
      image: "https://imgcdn.aplum.com/o_1gflk73soih5hg9f5f1dbjdjtf.png",
      url: "/imageView/1995",
    },
    
  ],
};
