import classnames from 'classnames';
import { useState } from 'react';

import { firstPage as data } from '../../data';
import styles from './styles.module.less';

const FirstPage = () => {
  const [showAppleQrCode, setShowAppleQrCode] = useState(false);
  const [showAndroidQrCode, setShowAndroidQrCode] = useState(false);
  return (
    <div className={classnames(styles["container"])}>
      <div className={classnames(styles["background"])}>
        <div className={classnames(styles["background-image"])}>
          <img
            className={classnames(styles["background-icon"])}
            src={data.icon}
            alt="icon"
          />
        </div>
      </div>
      <div className={classnames(styles["foreground"])}>
        <div className={classnames(styles["left"])}>
          {showAppleQrCode ? (
            <div className={classnames(styles["qr-code-container"])}>
              <img
                className={classnames(styles["qr-code"])}
                src={data.appleQRCode}
                alt="ios App下载二维码"
              />
              <div className={classnames(styles["qr-code-label"])}>
                扫描二维码下载App
              </div>
            </div>
          ) : null}
        </div>
        <div className={classnames(styles["middle"])}>
          <div className={classnames(styles["slogan"])}>
            <img
              className={classnames(styles["slogan-img"])}
              src={data.sloganImage}
              alt="Slogan"
            />
          </div>
          <div className={classnames(styles["button-container"])}>
            <div
              className={classnames(styles["download-button"])}
              onMouseEnter={() => setShowAppleQrCode(true)}
              onMouseLeave={() => setShowAppleQrCode(false)}
            >
              <div
                className={classnames(
                  styles["download-button-label-container"]
                )}
              >
                <img
                  className={classnames(styles["download-button-label-icon"])}
                  src={data.appleIcon}
                  alt="ios"
                />
                <div className={classnames(styles["download-button-label"])}>
                  AppStore
                </div>
              </div>
            </div>
            <div
              className={classnames(styles["download-button"])}
              onMouseEnter={() => setShowAndroidQrCode(true)}
              onMouseLeave={() => setShowAndroidQrCode(false)}
            >
              <div
                className={classnames(
                  styles["download-button-label-container"]
                )}
              >
                <img
                  className={classnames(styles["download-button-label-icon"])}
                  src={data.androidIcon}
                  alt="android"
                />
                <div className={classnames(styles["download-button-label"])}>
                  Android
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classnames(styles["right"])}>
          {showAndroidQrCode ? (
            <div className={classnames(styles["qr-code-container"])}>
              <img
                className={classnames(styles["qr-code"])}
                src={data.androidQRCode}
                alt="ios App下载二维码"
              />
              <div className={classnames(styles["qr-code-label"])}>
                扫描二维码下载App
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FirstPage;
