import classnames from "classnames";

import OneIconImage from "../../assets/01_icon.png";
import TwoIconImage from "../../assets/02_icon.png";
import ThreeIconImage from "../../assets/03_icon.png";
import BackgroundImage2 from "../../assets/buy_img2.png";
import BackgroundImage from "../../assets/fifth_bg.png";
import { hangye as data } from "../../data";
import styles from "./styles.module.less";

const IconMap = {
  "01": OneIconImage,
  "02": TwoIconImage,
  "03": ThreeIconImage,
};
const open = (url: any) => {
  window.open(url);
};
const Hangye = () => {
  return (
    <div className={classnames(styles["container"])}>
      <div className={classnames(styles["background"])}>
        <div className={classnames(styles["background-bg"])}>{/* <img className={classnames(styles["background-bg-img"])} src={BackgroundImage} alt="红布林背景" /> */}</div>
      </div>
      <div className={classnames(styles["foreground"])} style={{ position: "relative" }}>
        <div className={classnames(styles["english-title"])}>{data.englishTitle}</div>
        <div className={classnames(styles["title"])}>{data.title}</div>
        <div className={classnames(styles["items"])} style={{ display: "flex", flexWrap: "wrap" }}>
          {data.items.map((item: any, index: any) => (
            <div className={classnames(styles["item"])} key={index}>
              <div
                className={classnames(styles["item-idx"])}
                onClick={() => {
                  open(item.url);
                }}
              >
                <img src="https://imgcdn.aplum.com/o_1gfngbnslrfu12vv1ns4er46m0k.png" style={{ width: "33px", position: "absolute", bottom: "0", right: "0" }} alt="" />
                <img className={classnames(styles["item-idx-img"])} src={item.image} alt="index" />
              </div>
            </div>
          ))}
        </div>
        {/* <div className={classnames(styles["background-bg-2"])}>
          <img className={classnames(styles["background-bg-img"])} src={BackgroundImage2} alt="红布林背景2" />
        </div> */}
      </div>
    </div>
  );
};

export default Hangye;
