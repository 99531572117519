import classnames from "classnames";

import BuyerBackgroundImage from "../../assets/buyer_bg.png";
import SellerBackgroundImage from "../../assets/seller_bg.png";
import HBLBackgroundImage from "../../assets/third_bg.png";
import circle_1280 from "../../assets/circle_1280.png";
import { CircularPath as data } from "../../data";
import styles from "./styles.module.less";

const CyclicPath = () => {
  return (
    <div className={classnames(styles["container"])}>
      <div className={classnames(styles["foreground"])}>
        <div className={classnames(styles["background"])}></div>
        {/* <img className={classnames(styles["background"])} src={circle_1280} alt="" /> */}
        {/* <div className={classnames(styles["circular-container"], styles["buyer-container"])}>
          <div className={classnames(styles["content-container"])}>
            <div className={classnames(styles["english-title"])}>
              <span>{data.buyer.englishTile}</span>
            </div>
            <div className={classnames(styles["title"])}>
              <span>{data.buyer.title}</span>
            </div>
            <div className={classnames(styles["content"])}>
              <span>{data.buyer.content}</span>
            </div>
          </div>
          <div className={classnames(styles["image-container"])}>
            <img className={classnames(styles["image"])} src={BuyerBackgroundImage} alt="买家流程" />
          </div>
        </div>
        <div className={classnames(styles["plum-container"])}>
          <div className={classnames(styles["paths-container"])}>
            <ul className={classnames(styles["paths"])}>
              {data.buyer.items.map((path) => (
                <li key={path} className={classnames(styles["path"], styles["buyer-path"])}>
                  {path}
                </li>
              ))}
            </ul>
          </div>
          <img className={classnames(styles["image"])} src={"https://imgcdn.aplum.com/o_1gflld3jg1hn71eml7g21m7d1t2of.png"} alt="红布林" />
        </div>
        <div className={classnames(styles["circular-container"], styles["seller-container"])}>
          <div className={classnames(styles["image-container"])}>
            <img className={classnames(styles["image"])} src={SellerBackgroundImage} alt="卖家流程" />
          </div> */}
        {/* <div className={classnames(styles["paths-container"])}>
            <ul className={classnames(styles["paths"])}>
              {data.seller.items.map((path) => (
                <li key={path} className={classnames(styles["path"], styles["seller-path"])}>
                  {path}
                </li>
              ))}
            </ul>
          </div> */}
        {/* <div className={classnames(styles["content-container"])}>
            <div className={classnames(styles["english-title"])}>
              <span>{data.seller.englishTile}</span>
            </div>
            <div className={classnames(styles["title"])}>
              <span>{data.seller.title}</span>
            </div>
            <div className={classnames(styles["content"])}>
              <span>{data.seller.content}</span>
            </div>
          </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default CyclicPath;
