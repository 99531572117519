import classnames from 'classnames';

import OneIconImage from '../../assets/01_icon.png';
import TwoIconImage from '../../assets/02_icon.png';
import ThreeIconImage from '../../assets/03_icon.png';
import BackgroundImage2 from '../../assets/buy_img2.png';
import BackgroundImage from '../../assets/fifth_bg.png';
import { authentication as data } from '../../data';
import styles from './styles.module.less';

const IconMap = {
  "01": OneIconImage,
  "02": TwoIconImage,
  "03": ThreeIconImage,
};

const Authentication = () => {
  return (
    <div className={classnames(styles["container"])}>
      <div className={classnames(styles["background"])}>
        <div className={classnames(styles["background-bg"])}>
          <img
            className={classnames(styles["background-bg-img"])}
            src={BackgroundImage}
            alt="红布林背景"
          />
        </div>
      </div>
      <div className={classnames(styles["foreground"])}>
        <div className={classnames(styles["english-title"])}>
          {data.englishTitle}
        </div>
        <div className={classnames(styles["title"])}>{data.title}</div>
        <div className={classnames(styles["items"])}>
          {data.items.map((item) => (
            <div className={classnames(styles["item"])} key={item.idx}>
              <div className={classnames(styles["item-idx"])}>
                <img
                  className={classnames(styles["item-idx-img"])}
                  src={IconMap[item.idx as "01" | "02" | "03"]}
                  alt="index"
                />
              </div>
              <div className={classnames(styles["item-title"])}>
                {item.title}
              </div>
              <div className={classnames(styles["item-separator"])}></div>
              <div className={classnames(styles["item-content"])}>
                {item.content}
              </div>
            </div>
          ))}
        </div>
        <div className={classnames(styles["background-bg-2"])}>
          <img
            className={classnames(styles["background-bg-img"])}
            src={BackgroundImage2}
            alt="红布林背景2"
          />
        </div>
      </div>
    </div>
  );
};

export default Authentication;
