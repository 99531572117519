import androidIcon from '../assets/android_icon.png';
import androidQRCode from '../assets/android_qrCode.png';
import appleIcon from '../assets/apple_icon.png';
import firstIcon from '../assets/first_icon.png';
import appleQRCode from '../assets/ios_qrCode.png';
import sloganImage from '../assets/slogan.png';

const data = {
  sloganImage: sloganImage,
  icon: firstIcon,
  appleIcon,
  androidIcon,
  appleQRCode,
  androidQRCode,
};

export default data;
