import BadGirlPic1 from '../assets/users/BadGirl/1.png';
import BadGirlPic2 from '../assets/users/BadGirl/2.png';
import BadGirlAvatar from '../assets/users/BadGirl/a.png';
import hwqPic1 from '../assets/users/hwq/1.png';
import hwqPic2 from '../assets/users/hwq/2.png';
import hwqAvatar from '../assets/users/hwq/a.png';
import IrisPic1 from '../assets/users/Iris/1.png';
import IrisPic2 from '../assets/users/Iris/2.png';
import IrisAvatar from '../assets/users/Iris/a.png';
import koiowwwPic1 from '../assets/users/koiowww/1.png';
import koiowwwPic2 from '../assets/users/koiowww/2.png';
import koiowwwAvatar from '../assets/users/koiowww/a.png';
import yzxmlPic1 from '../assets/users/yzxml/1.png';
import yzxmlPic2 from '../assets/users/yzxml/2.png';
import yzxmlAvatar from '../assets/users/yzxml/a.png';

export default {
  englishTitle: "Users' voice",
  title: "她们都在用",
  voices: [
    {
      name: "买家Iris",
      avatar: IrisAvatar,
      voice:
        "一直想入手LouisVuitton Monogram Trousse Toilette 23日用品包，很幸运在红布林找到了同款，立马下单，此包容量大，图二全部装进去还有很大富裕空间，是一个非常耐用的收纳包，很喜欢。",
      pictures: [IrisPic1, IrisPic2],
    },
    {
      name: "买家洪文琦",
      avatar: hwqAvatar,
      voice:
        "作为一个喜欢耳饰的无耳洞星人，只能戴耳夹，国内少有专门做耳夹的品牌，某宝假的塑料的又不想带。红布林上的古着耳夹品类很多，而且都是经典品牌，成色更好。这对Avon领完优惠券不到一百还是顺丰包邮，血嫌！",
      pictures: [hwqPic1, hwqPic2],
    },
    {
      name: "买家宇宙小米粒",
      avatar: yzxmlAvatar,
      voice:
        "晒单～哈哈哈～去年买的包包～日常搭配超高！每次出去玩都背这款，不管是碎花裙还是牛仔衫，怎么样都很百搭，金色的搭扣也很复古，显得更精致，总之特别喜欢！偷偷告诉你们，朋友也已经下单啦！",
      pictures: [yzxmlPic1, yzxmlPic2],
    },
    {
      name: "买家 koiowWw",
      avatar: koiowwwAvatar,
      voice:
        "试水红布林后很满意，接二连三没忍住，红布林真的太好逛啦！上周又淘了Armani的白色简约腕表和Tiffany的项链，日常非常好搭，朋友看上了也都想买。（ PS ：红布林已经在朋友圈普及）。",
      pictures: [koiowwwPic1, koiowwwPic2],
    },
    {
      name: "买家 Bad Girl",
      avatar: BadGirlAvatar,
      voice:
        "第一次在红布林下单，入了一款Daniel Welington的手表，戴上很好看，跟买的手链很配，整体感觉超值，虽然是二手但是没有特别大的划痕，保养的很好，手续齐全，而且有鉴定保障。",
      pictures: [BadGirlPic1, BadGirlPic2],
    },
  ],
};
