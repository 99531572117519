import classnames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';

import quoteImage from '../../assets/quote_icon.png';
import { userVoice as data } from '../../data';
import styles from './styles.module.less';

const time = 5000;

const UserVoice = () => {
  const [isAutoChangeUser, setIsAutoChangeUser] = useState(true);

  const [currentIndex, setCurrentIndex] = useState(0);
  const currentUser = useMemo(() => {
    const len = data.voices.length;
    return data.voices[currentIndex % len];
  }, [currentIndex]);

  const timer = useRef<any>(undefined);
  const animateTimer = useRef<any>(undefined);

  useEffect(() => {
    if (!timer) {
      clearTimeout(timer);
    }
    if (isAutoChangeUser) {
      timer.current = setTimeout(() => {
        setCurrentIndex((currentIndex + 1) % data.voices.length);
      }, time);
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [isAutoChangeUser, currentIndex]);

  useEffect(() => {
    const ref = document.querySelector(
      `.${styles["avatar"]}.${styles["is-active"]}`
    );
    let degree = 0,
      maxDegree = 100;
    animateTimer.current = setInterval(() => {
      if (ref) {
        if (degree <= maxDegree) {
          (ref as any)!.style.background = `conic-gradient(rgb(242, 10, 10) ${Math.floor(
            3.6 * degree
          )}deg, rgb(247, 247, 247) 0deg)`;
          degree++;
        }
      }
    }, time / 100);

    return () => {
      (ref as any)!.style.background = `#f7f7f7`;
      clearInterval(animateTimer.current);
    };
  }, [currentIndex]);

  return (
    <div className={classnames(styles["container"])}>
      <div className={classnames(styles["foreground"])}>
        <div className={classnames(styles["english-title"])}>
          {data.englishTitle}
        </div>
        <div className={classnames(styles["title"])}>{data.title}</div>
        <div className={classnames(styles["avatars"])}>
          {data.voices.map(({ avatar, name }, index) => {
            return (
              <div
                key={index}
                className={classnames(styles["avatar-container"])}
              >
                <div
                  className={classnames(styles["avatar"], {
                    [styles["is-active"]]: index === currentIndex,
                  })}
                  onMouseEnter={() => {
                    setCurrentIndex(index);
                    setIsAutoChangeUser(false);
                  }}
                  onMouseLeave={() => {
                    setIsAutoChangeUser(true);
                  }}
                >
                  <img
                    className={classnames(styles["avatar-img"])}
                    src={avatar}
                    alt={name}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className={classnames(styles["content-container"])}>
          <img
            className={classnames(styles["quote"])}
            src={quoteImage}
            alt="引号"
          />
          <div className={classnames(styles["voice-container"])}>
            <div className={classnames(styles["voice-user-name"])}>
              {currentUser.name + "："}
            </div>
            <div className={classnames(styles["voice-content"])}>
              {`"${currentUser.voice}"`}
            </div>
            <div className={classnames(styles["voice-picture"])}>
              <div className={classnames(styles["pictures"])}>
                {currentUser.pictures.map((pic) => {
                  return (
                    <img
                      key={pic}
                      className={classnames(styles["picture"])}
                      src={pic}
                      alt={currentUser.name}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserVoice;
