export default {
  englishTitle: "Authentication",
  title: "鉴定服务",
  items: [
    {
      idx: "01",
      title: "中检战略合作",
      content:
        "红布林与中检集团奢侈品鉴定中心达成长期战略合作，致力于在循环时尚领域提升非标商品标准化的规范，为正品保驾护航，共创良好的消费市场环境。",
    },
    {
      idx: "02",
      title: "建立自有鉴定专家团",
      content:
        "专家团由从业10年+，鉴定量超10W+的资深鉴定师组成，坚持攻克鉴定难点，保证红布林鉴定团队鉴定技术与时俱进。",
    },
    {
      idx: "03",
      title: "制定高于行业的鉴定标准",
      content:
        "制定高于行业普遍采用的鉴定标准来要求红布林鉴定团队；配备鉴定实验室，高精密“科研级别”鉴定检测仪器辅助辨别真伪。目前红布林已入选「奢侈品行业标准化技术委员会」成员。",
    },
  ],
};
