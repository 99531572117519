import news1Picture from "../assets/news1.png";
import news2Picture from "../assets/news2.jpg";
import news3Picture from "../assets/news3.png";
import news4Picture from "../assets/news4.jpg";
import news5Picture from "../assets/news5.png";
import news6Picture from "../assets/news6.png";
import SVIOQrCodePicture from "../assets/Plum_SVIP_Buyer.png";
import wechatQrCodePicture from "../assets/Plum_Wechat.png";
import BackgroundImage from "../assets/sixth_bg.png";

export default {
  backgroundImg: BackgroundImage,
  englishTitle: "About us",
  title: "关于红布林",
  content:
    "红布林成立于2017年，是循环时尚生活方式电商平台，覆盖全品类时尚商品，为用户提供既买又卖的新生活体验，自由探索品质生活。\n红布林高效链接了买卖双方，采用C2B2C、B2B2C模式，为用户提供时尚单品买卖一体化的全链条标准化服务。一方面，为买家提供更高性价比的时尚单品，以及完善的鉴定和售前售后等服务；另一方面，为卖家提供一站式的寄售、回收服务，包括专业的拍摄、仓储物流、智能定价、7X13h贴心客服等，真正实现足不出户即可变现。\n我们倡导循环时尚生活方式，致力于让千万用户的衣橱流转起来；以新的低碳消费模式，让地球更加绿色可持续。",
  brandNews: "品牌动态",
  news: [
    {
      title: "《红布林完成1亿美元C轮融资》",
      content:
        "循环时尚品牌红布林完成1亿美元C轮融资，创下2022年二奢领域融资事件的金额新高。红布林创始人兼CEO徐薇告诉36氪，本轮融资资金将主要用于服务标准化、用户体验提升、产品研发和整体数据库搭建。",
      link: "https://36kr.com/p/1982300247081992",
      source: "36氪 2022-11-01",
      picture: news1Picture,
    },
    {
      title: "《机构发布循环消费倡议：倡导绿色低碳生活方式》",
      content:
        "2022年11月，红布林、中国循环经济协会互联网+资源循环利用专委会、中国生物多样性保护与绿色发展基金会低碳工坊工作组、中检集团奢侈品鉴定中心等联合发布《循环消费倡议书》，呼吁消费者用环保的方式参与“双11”购物节。",
      link: "https://baijiahao.baidu.com/s?id=1748638562718019210&wfr=spider&for=pc",
      source: "中国新闻网 2022-11-05",
      picture: news2Picture,
    },
    {
      title: "《报告显示循环时尚行业成为消费市场复苏的新增量》",
      content:
        "2023年1月，红布林联合益普索Ipsos发布《2023循环时尚行业趋势报告》，报告指出，在低碳理念兴起的当下，让时尚产品在消费端二度流通及循环的循环时尚行业，已成为消费市场复苏的新增量。",
      link: "http://finance.people.com.cn/n1/2023/0110/c1004-32603367.html",
      source: "人民网 2023-01-10",
      picture: news3Picture,
    },
    {
      title: "《二手循环电商红布林，如何推动时尚行业减碳？》",
      content:
        "红布林是国内首批将碳减排团体标准与用户行为相关联的平台，其在APP端内开发的“循环时尚环保行动”碳减排功能，使每一次成功寄卖、转卖、回收商品，买家和卖家皆可累计碳减排。",
      link: "https://www.jiemian.com/article/8954807.html",
      source: "界面新闻 2023-02-28",
      picture: news4Picture,
    },
    {
      title: "《红布林牵头发布循环减碳行动倡议呼吁共建绿色地球》",
      content:
        "“拥抱绿色消费理念，成为‘绿色宣传大使’；购买循环商品，多参与绿色消费；倡导循环利用，让更多闲置实现物尽其用。”4月22日世界地球日，红布林携手中国循环经济协会互联网+资源循环利用专委会、中检集团奢侈品鉴定中心等共同发起《循环的地球——2023地球日循环减碳行动倡议》",
      link: "https://s.cyol.com/articles/2023-04/22/content_5xWqmlSM.html?gid=gDajO4kX",
      source: "中国青年报 2023-04-22",
      picture: news5Picture,
    },
    {
      title: "《循环消费正当时二手电商“6·18”数据亮眼》",
      content:
        "2023年“618”期间，红布林吸引数十万循环青年参与大促活动，参与活动的商家货量同比增长319%，平台寄卖、回收商品量创下历史新高，同比增长51%，平台数据表现亮眼。",
      link: "http://www.xinhuanet.com/fashion/20230625/76559159e1a344f0b0c7e3296df3619a/c.html",
      source: "新华网 2023-06-26",
      picture: news6Picture,
    },
  ],
  contractUs: "联系我们",
  contractWays: [
    {
      label: "品牌合作：",
      value: "contact@zhuanzhuan.com",
    },
    {
      label: "媒体合作：",
      value: "hbl-pr@zhuanzhuan.com",
    },
    // {
    //   label: "加入我们：",
    //   value: "hr-hbl@zhuanzhuan.com",
    // },
  ],
  qrCodes: [
    {
      title: "红布林微信公众号",
      picture: wechatQrCodePicture,
    },
    {
      title: "红布林买家顾问",
      picture: SVIOQrCodePicture,
    },
  ],
  footer: [
    "致美生活（北京）科技有限公司 公司地址:北京市顺义区安祥街12号院1号楼6层613室  联系电话:010-86207790 ",
  ],
};
