export default {
  title: "循环时尚",
  englishTitle: "Circular fashion",
  content:
    "2017年，红布林诞生！一路走来，我们与许多热爱美、积极生活的用户共创了循环时尚这种新的生活方式。我们都相信，好品牌、好品质、好设计的单品值得被循环起来，并希望将这种价值观传递给更多人。\n成立至今，很骄傲地说，我们做到了让全品类、2000+时尚品牌都可以轻松循环，并为每一个品类打造了可靠的鉴定、评级和定价服务。我们也见证了越来越多的用户，更勇敢地尝试多元的品牌和设计，更轻松地处理自己的闲置。\n这是一个新世界，也是一种新生活，让每一件商品都变得物尽其用，每一种审美价值都被鼓励，地球环境也因此变得更可持续发展。故事还在继续，期待你的加入～",
  items: [
    {
      key: "brands",
      value: "2000+",
      label: "红布林覆盖2000+时尚品牌",
    },
    {
      key: "maintenance",
      value: "20",
      label: "创建20道鉴定养护流程",
    },
    {
      key: "sellerRate",
      value: "95%",
      label: "95%的商品可在30天内售出",
    },
    {
      key: "environmentalProtection",
      value: "50,531,589",
      label: "截至2022年12月31日累计减少碳排放50,531,589kg",
      marquee: true,
    },
  ],
};
