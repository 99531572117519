import React from "react";

import { Slide, SlideContainer } from "../components";
import { AboutUs, Authentication, CircularFashion, CircularPath, FirstPage, UserVoice, Hangye } from "../slides";

function Main() {
    return (
        <SlideContainer>
            <Slide label="首页" key="首页">
                <FirstPage />
            </Slide>
            <Slide label="循环时尚" key="循环时尚">
                <CircularFashion />
            </Slide>
            <Slide label="循环路径" key="循环路径">
                <CircularPath />
            </Slide>
            <Slide label="用户心声" key="用户心声">
                <UserVoice />
            </Slide>
            <Slide label="鉴定服务" key="鉴定服务">
                <Authentication />
            </Slide>
            <Slide label="行业洞察" key="行业洞察">
                <Hangye />
            </Slide>
            <Slide label="关于我们" key="关于我们">
                <AboutUs />
            </Slide>
        </SlideContainer>
    );
}

export default Main;
