function throttle(fn: Function, timeout: number) {
  let timer: any = null;
  return function () {
    // @ts-ignore
    const _this = this;
    if (timer) {
      return;
    }
    fn.apply(_this, arguments);
    timer = setTimeout(() => {
      timer = null;
    }, timeout);
  };
}

export default throttle;
