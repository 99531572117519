import classnames from 'classnames';
import { Fragment } from 'react';

import Bg from '../../assets/second_bg.png';
import { CircularFashion as data } from '../../data';
import styles from './styles.module.less';

const CircularFashion = () => {
  return (
    <div className={classnames(styles["container"])}>
      <div className={classnames(styles["background"])}>
        <div className={classnames(styles["background-bg"])}>
          <img
            className={classnames(styles["background-bg-img"])}
            src={Bg}
            alt="红布林背景"
          />
        </div>
      </div>
      <div className={classnames(styles["foreground"])}>
        <div className={classnames(styles["english-title"])}>
          {data.englishTitle}
        </div>
        <div className={classnames(styles["title"])}>{data.title}</div>
        <div className={classnames(styles["content"])}>
          {data.content.split("\n").map((ctx) => (
            <div key={ctx}>
              <span>{ctx}</span>
              <br />
            </div>
          ))}
        </div>
        <div className={classnames(styles["items"])}>
          {data.items.map((item, index) => (
            <Fragment key={index}>
              <div className={classnames(styles["item"])} key={index}>
                <div className={classnames(styles["item-content"])}>
                  <div
                    className={classnames(
                      styles["item-icon"],
                      styles[`item-icon-${item.key}`]
                    )}
                  ></div>
                  <div className={classnames(styles["item-value"])}>
                    {item.value}
                  </div>
                  <div className={classnames(styles["item-label-container"])}>
                    <div
                      className={classnames(styles["item-label"], {
                        [styles["is-marquee"]]: item.marquee,
                      })}
                    >
                      {item.marquee
                        ? item.label + "   " + item.label + "   "
                        : item.label}
                    </div>
                  </div>
                </div>
              </div>
              {index !== 0 && index !== 3 ? (
                <div className={classnames(styles["item-separator"])}></div>
              ) : null}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CircularFashion;
