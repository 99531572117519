import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

function ImageView() {
  const { id } = useParams();

  const url = useMemo(() => {
    console.log("id", id);
    switch (id) {
      case "2021RH1": // 2021上半年二手奢侈品销售榜
        return [
          "https://imgcdn.aplum.com/o_1gfqaq8l8h0i1dj51rnp29ni1rf.jpeg",
          "https://imgcdn.aplum.com/o_1gfqar6ltng317e21c5m2gc1f00k.jpeg",
          "https://imgcdn.aplum.com/o_1gfqarmepvbn10f513e942c10q9p.jpeg",
          "https://imgcdn.aplum.com/o_1gfqasa3r1lsq19p5510173mqj5u.jpeg",
        ];
      // return "https://agreement.cdn.aplum.com/2021%E4%B8%8A%E5%8D%8A%E5%B9%B4%E4%BA%8C%E6%89%8B%E5%A5%A2%E4%BE%88%E5%93%81%E6%B6%88%E8%B4%B9%E6%A6%9C.jpeg";
      case "2021RH2": // 2021下半年二手奢侈品销售榜
        return [
          "https://imgcdn.aplum.com/o_1gfqa8be7dc01r4r4covmmf5n1n.jpg",
          "https://imgcdn.aplum.com/o_1gfqa7sl21gpo2l15mm1jvp1eoh1i.jpg",
          "https://imgcdn.aplum.com/o_1gfqa7dl11t7p1n2i1s4h33q1orj1d.jpg",
          "https://imgcdn.aplum.com/o_1gfqa706p88n10915v47ubobe18.jpg",
          "https://imgcdn.aplum.com/o_1gfqa6dufmk98l6l2gsjh15k413.jpg",
          "https://imgcdn.aplum.com/o_1gfqa5veqo2n9lt1laf9825ou.jpg",
          "https://imgcdn.aplum.com/o_1gfqa5h6up1t15j71gd2snkcq3p.jpg",
          "https://imgcdn.aplum.com/o_1gfqa50iuril1mhr1c164211cmk.jpg",
          "https://imgcdn.aplum.com/o_1gfqa3j7d1o671od318kf18jb3vpf.jpg",
        ];
      // return "https://agreement.cdn.aplum.com/2021%E4%B8%8B%E5%8D%8A%E5%B9%B4%E4%BA%8C%E6%89%8B%E5%A5%A2%E4%BE%88%E5%93%81%E6%B6%88%E8%B4%B9%E6%A6%9C.jpg";
      case "2021E": // 2021中国二手奢侈品电商平台消费洞察报告
        return [
          "https://imgcdn.aplum.com/o_1gfqav7lknm9rf1687adj4bf.jpg",
          "https://imgcdn.aplum.com/o_1gfqavteavm21hpidtb1l7s7ptk.jpg",
          "https://imgcdn.aplum.com/o_1gfqb0g2co3i1okcdp8dpe1t07p.jpg",
          "https://imgcdn.aplum.com/o_1gfqb0usm175vpa817n1e1046nu.jpg",
          "https://imgcdn.aplum.com/o_1gfqb1f4lvgn1ijo129q1hoa1o7213.jpg",
          "https://imgcdn.aplum.com/o_1gfqb1veedeo1qft17db1dks12lv18.jpg",
          "https://imgcdn.aplum.com/o_1gfqb2ef21luc10dqdp41ot2ni31d.jpg",
          "https://imgcdn.aplum.com/o_1gfqb2sli1pan1i2465t1q2m16ai1i.jpg",
          "https://imgcdn.aplum.com/o_1gfqb39ggq9qr6ce8p1d0611lo1n.jpg",
        ];
      // return "https://agreement.cdn.aplum.com/2021%E4%B8%AD%E5%9B%BD%E4%BA%8C%E6%89%8B%E5%A5%A2%E4%BE%88%E5%93%81%E7%94%B5%E5%95%86%E5%B9%B3%E5%8F%B0%E6%B6%88%E8%B4%B9%E6%B4%9E%E5%AF%9F%E6%8A%A5%E5%91%8A.jpg";
      case "1995": // 95后职场人时尚消费报告
        return [
          "https://imgcdn.aplum.com/o_1gfqb5sn7mi61sdt1nk3fpk4v5f.jpeg",
          "https://imgcdn.aplum.com/o_1gfqb6g1l1gao115419q31lg7atk.jpeg",
          "https://imgcdn.aplum.com/o_1gfqb70vc1e4r1ch5jf51j9578ep.jpeg",
          "https://imgcdn.aplum.com/o_1gfqb7l641p9e11nrv471kh4vsu.jpeg",
          "https://imgcdn.aplum.com/o_1gfqb80lp10qv10bq8m710o1gvm13.jpeg",
          "https://imgcdn.aplum.com/o_1gfqb8cprqsmrmr1qhv1gh71jh718.jpeg",
          "https://imgcdn.aplum.com/o_1gfqb8oa3112215g96upc9h1ik01d.jpeg",
          "https://imgcdn.aplum.com/o_1gfqb96b81ajv13f11asg13v397u1i.jpeg",
          "https://imgcdn.aplum.com/o_1gfqb9at5s8l18ju1g1d1dp9rke1n.jpeg",
          "https://imgcdn.aplum.com/o_1gfqb9g1hckhu8r1n271gc10gc1s.jpeg",
          "https://imgcdn.aplum.com/o_1gfqb9jst1hvf1triqtl18itqhl21.jpeg",
        ];
      // return "https://agreement.cdn.aplum.com/95%E5%90%8E%E8%81%8C%E5%9C%BA%E4%BA%BA%E6%97%B6%E5%B0%9A%E6%B6%88%E8%B4%B9%E6%8A%A5%E5%91%8A.jpg";
      case "2018": // 202018中国二手时尚数据报告
        return [
          "https://imgcdn.aplum.com/o_1gfqbdtdpf664b011eo18fl18a2f.jpeg",
          "https://imgcdn.aplum.com/o_1gfqbenc813jejr31b4t16q71srvk.jpeg",
          "https://imgcdn.aplum.com/o_1gfqbf429a2k1bs011hkra6pnp.jpeg",
          "https://imgcdn.aplum.com/o_1gfqbfhfd1e6s1mn35mj1hqi1alcu.jpeg",
          "https://imgcdn.aplum.com/o_1gfqbfsv12paiuh49s1hnh1mag13.jpeg",
          "https://imgcdn.aplum.com/o_1gfqbgf00bo11ie2107a8gk2d718.jpeg",
          "https://imgcdn.aplum.com/o_1gfqbgrbcg7u13rhvvfb8hvon1d.jpeg",
        ];
      // return "https://agreement.cdn.aplum.com/Plum%202018%E4%B8%AD%E5%9B%BD%E4%BA%8C%E6%89%8B%E6%97%B6%E5%B0%9A%E6%95%B0%E6%8D%AE%E6%8A%A5%E5%91%8A.png";
      default:
        return "";
    }
  }, [id]);

  return (
    <div style={{ width: "100vw", overflowX: "hidden", boxSizing: "border-box" }}>
      {Array.isArray(url) ? url.map((i) => <img src={i} alt="" style={{ width: "60%", display: "block", margin: "0 auto" }} />) : <img src={url} alt="" style={{ width: "80%", display: "block" }} />}
    </div>
  );
}

export default ImageView;
