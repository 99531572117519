import classnames from 'classnames';
import React, { useCallback, useMemo, useState, WheelEvent } from 'react';

import ArrowIcon from '../../assets/arrow_icon.png';
import LogoRed from '../../assets/logo_red.png';
import LogoWhite from '../../assets/logo_white.png';
import { throttle } from '../../utils';
import styles from './styles.module.less';
import { SlideContainerProps } from './types';

const SlideContainer = (props: SlideContainerProps) => {
  const { children } = props;
  const childArray = useMemo(
    () => (Array.isArray(children) ? children : [children]),
    [children]
  );

  const [currentPageIndex, setCurrentIndexIndex] = useState(0);

  const labels = useMemo(() => {
    return childArray
      .filter((child) => child.type?.displayName === "Slide")
      .map((child) => child?.props?.label);
  }, [childArray]);

  const currentChild = useMemo(() => {
    return childArray[currentPageIndex % childArray.length];
  }, [currentPageIndex, childArray]);

  const isLastPage = useMemo(() => {
    return currentPageIndex === childArray.length - 1;
  }, [childArray, currentPageIndex]);

  const isDarkPage = useMemo(() => currentPageIndex === 0, [currentPageIndex]);

  const prevPage = useCallback(() => {
    setCurrentIndexIndex((index) => (index > 0 ? index - 1 : index));
  }, []);

  const nextPage = useCallback(() => {
    setCurrentIndexIndex((index) =>
      index === childArray.length - 1 ? index : index + 1
    );
  }, [childArray.length]);

  const handleScroll = useCallback(
    throttle((e: WheelEvent<HTMLDivElement>) => {
      if (e.deltaY < 0) {
        prevPage();
      } else if (e.deltaY > 0) {
        nextPage();
      }
    }, 1000),
    [prevPage, nextPage]
  );

  return (
    <div
      className={classnames(styles["slide-container"])}
      onWheel={handleScroll}
    >
      <div
        className={classnames(styles["foreground"], {
          [styles["dark-page"]]: isDarkPage,
        })}
      >
        <div className={classnames(styles["logo-container"])}>
          <img
            className={classnames(styles["logo-img"])}
            src={isDarkPage ? LogoWhite : LogoRed}
            alt=""
          />
        </div>
        <div className={classnames(styles["nav"])}>
          <ul>
            {labels.map((label, index) => (
              <div
                key={label}
                className={classnames(styles["nav-item"], {
                  [styles["is-active"]]: currentPageIndex === index,
                })}
                onClick={() => setCurrentIndexIndex(index)}
              >
                {currentPageIndex === index ? (
                  <div className={classnames(styles["nav-active-icon"])}></div>
                ) : null}
                <span className={classnames(styles["nav-label"])}>{label}</span>
              </div>
            ))}
          </ul>
        </div>
        <div className={classnames(styles["nav-button-container"])}>
          {isLastPage ? null : (
            <div
              className={classnames(styles["nav-button"])}
              onClick={nextPage}
            >
              <img
                className={classnames(styles["nav-button-img"])}
                src={ArrowIcon}
                alt="下一页"
              />
            </div>
          )}
        </div>
      </div>
      <div className={classnames(styles["nav-content"])}>{currentChild}</div>
    </div>
  );
};

export default SlideContainer;
