import {
    createBrowserRouter,
} from "react-router-dom";
import Main from "./views/main";
import ImageView from './views/imageView';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Main />,
    },
    {
        path: "/imageView/:id",
        element: <ImageView />,
    }
]);

export default router;